import React from 'react'
import classNames from 'classnames'
import Check from 'wix-ui-icons-common/Check'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getPlanBenefitText, getPlanPriceText, planVisible} from '../../../../selectors/paid-plans'
import s from '../membership-offers.scss'
import c from '../../../classnames.scss'
import {useCurrencyFormatter} from '../../../../../../../commons/hooks/currency'
import {PlansProps} from './index'

export const Plans: React.FC<PlansProps> = ({plans, t}) => {
  const {getFormattedMoney} = useCurrencyFormatter()
  return (
    <>
      {plans.map(
        ({plan, infoLine}) =>
          planVisible(plan) && (
            <div
              className={classNames(s.membershipItem, c.membershipOffersBorderColor)}
              data-hook={`${DH.MEMBERSHIP_OFFERS_PLAN}-${plan.id}`}
              key={plan.id}
            >
              <div className={s.content}>
                <div className={s.primary} data-hook={DH.MEMBERSHIP_OFFERS_PLAN_NAME}>
                  {plan.name}
                </div>
                <div className={s.secondary} data-hook={DH.MEMBERSHIP_OFFERS_PLAN_BENEFIT}>
                  {getPlanBenefitText(plan, t)}
                </div>
              </div>
              <div className={s.pricing}>
                <div
                  className={classNames(s.primary, {[s.withIcon]: plan.purchased})}
                  data-hook={DH.MEMBERSHIP_OFFERS_PLAN_PRICING}
                >
                  {plan.purchased ? (
                    <>
                      <Check className={s.purchasedIcon} />
                      {t('membership_purchased')}
                    </>
                  ) : (
                    getPlanPriceText(plan, t, getFormattedMoney)
                  )}
                </div>
                <div className={s.secondary} data-hook={DH.MEMBERSHIP_OFFERS_PLAN_INFO}>
                  {infoLine}
                </div>
              </div>
            </div>
          ),
      )}
    </>
  )
}
