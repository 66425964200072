import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {hookToAttributeSelector} from '@wix/panda-js-utils'
import {RsvpInfoButton} from '../rsvp-info-button'
import {EditRegistrationProps} from './index'

export const EditRegistrationButton: React.FC<EditRegistrationProps> = ({
  t,
  buttonTextTranslation,
  messageTranslation,
  changeMemberRsvp,
}) => (
  <RsvpInfoButton
    messages={{
      exploreEventsActionLabel: t(buttonTextTranslation),
      message: t(messageTranslation),
    }}
    dataHook={DH.UPDATE_MEMBER_EVENT}
    onClick={() => changeMemberRsvp(hookToAttributeSelector(DH.RSVP_INFO_BUTTON))}
  />
)
