import React from 'react'
import loadable from '@wix/yoshi-flow-editor/loadable'
import {ModalsProps} from './interfaces'
import {OrderSummary} from './order-summary'
import {NativeModal} from './constants'
import {TicketsDownload} from './tickets-download'

const SeatingPlanModal = loadable(() => import(/* webpackChunkName: "seating-plan-modal" */ './seating-plan'))

export const Modals = ({modalType, plan}: ModalsProps) => {
  switch (modalType) {
    case NativeModal.ORDER_SUMMARY:
      return <OrderSummary />
    case NativeModal.TICKETS_DOWNLOAD:
      return <TicketsDownload />
    case NativeModal.SEATING_PLAN:
      if (!plan) {
        return null
      }

      return <SeatingPlanModal />
    default:
      return null
  }
}
