(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@wix/image-client-api"), require("react"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["imageClientSDK", "react", "reactDOM", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["details-pageViewerWidgetNoCss"] = factory(require("@wix/image-client-api"), require("react"), require("react-dom"), require("lodash"));
	else
		root["details-pageViewerWidgetNoCss"] = factory(root["imageClientSDK"], root["React"], root["ReactDOM"], root["_"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__58115__, __WEBPACK_EXTERNAL_MODULE__27762__, __WEBPACK_EXTERNAL_MODULE__88677__, __WEBPACK_EXTERNAL_MODULE__17607__) {
return 